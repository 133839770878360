var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Modal",
    {
      staticClass: "tCombo",
      attrs: { title: _vm.editTitle, "mask-closable": false },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "Form",
        {
          ref: "tComboForm",
          attrs: {
            model: _vm.tComboForm,
            rules: _vm.tComboFormRule,
            "label-width": 100,
          },
        },
        [
          _c(
            "FormItem",
            { attrs: { label: "套餐名称", prop: "name" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入套餐名称",
                },
                model: {
                  value: _vm.tComboForm.name,
                  callback: function ($$v) {
                    _vm.$set(_vm.tComboForm, "name", $$v)
                  },
                  expression: "tComboForm.name",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "封面图片地址", prop: "url" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入封面图片地址",
                },
                model: {
                  value: _vm.tComboForm.url,
                  callback: function ($$v) {
                    _vm.$set(_vm.tComboForm, "url", $$v)
                  },
                  expression: "tComboForm.url",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "套餐类别", prop: "type" } },
            [
              _c(
                "RadioGroup",
                {
                  attrs: { disabled: _vm.disabled },
                  model: {
                    value: _vm.tComboForm.type,
                    callback: function ($$v) {
                      _vm.$set(_vm.tComboForm, "type", $$v)
                    },
                    expression: "tComboForm.type",
                  },
                },
                _vm._l(_vm.typeRadio, function (item) {
                  return _c(
                    "Radio",
                    { key: item.value, attrs: { label: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "简拼", prop: "simpleSpell" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入简拼",
                },
                model: {
                  value: _vm.tComboForm.simpleSpell,
                  callback: function ($$v) {
                    _vm.$set(_vm.tComboForm, "simpleSpell", $$v)
                  },
                  expression: "tComboForm.simpleSpell",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "适合性别", prop: "fitSex" } },
            [
              _c(
                "Select",
                {
                  attrs: {
                    disabled: _vm.disabled,
                    placeholder: "请选择",
                    clearable: "",
                  },
                  model: {
                    value: _vm.tComboForm.fitSex,
                    callback: function ($$v) {
                      _vm.$set(_vm.tComboForm, "fitSex", $$v)
                    },
                    expression: "tComboForm.fitSex",
                  },
                },
                _vm._l(_vm.fitSexPriority, function (item, i) {
                  return _c(
                    "Option",
                    { key: item.value, attrs: { value: item.value } },
                    [_vm._v(_vm._s(item.title))]
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "排序", prop: "orderNum" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入排序",
                },
                model: {
                  value: _vm.tComboForm.orderNum,
                  callback: function ($$v) {
                    _vm.$set(_vm.tComboForm, "orderNum", $$v)
                  },
                  expression: "tComboForm.orderNum",
                },
              }),
            ],
            1
          ),
          _c(
            "FormItem",
            { attrs: { label: "套餐介绍", prop: "remark" } },
            [
              _c("Input", {
                attrs: {
                  disabled: _vm.disabled,
                  type: "text",
                  maxlength: 50,
                  placeholder: "请输入套餐介绍",
                },
                model: {
                  value: _vm.tComboForm.remark,
                  callback: function ($$v) {
                    _vm.$set(_vm.tComboForm, "remark", $$v)
                  },
                  expression: "tComboForm.remark",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "Button",
            {
              attrs: { type: "text" },
              on: {
                click: function ($event) {
                  _vm.show = false
                },
              },
            },
            [_vm._v("取消")]
          ),
          !_vm.disabled
            ? _c(
                "Button",
                {
                  attrs: { type: "primary", loading: _vm.loading },
                  on: { click: _vm.handelSubmit },
                },
                [_vm._v("提交")]
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }